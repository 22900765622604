
import React, { useEffect } from 'react';
import useWebSocketDataStore from '../Store/WebSocketDataStore.jsx';
import * as math from 'mathjs'; // Assuming you have mathjs for mean and sum calculations

const CorrelationComponent = () => {
    const data = useWebSocketDataStore(state => state.data);
    const setCorrelations = useWebSocketDataStore(state => state.setCorrelations);

    const calculateCorrelation = (arr1, arr2) => {
        const mean1 = math.mean(arr1);
        const mean2 = math.mean(arr2);

        const numerator = math.sum(arr1.map((x, i) => (x - mean1) * (arr2[i] - mean2)));
        const denominator = Math.sqrt(math.sum(arr1.map(x => Math.pow(x - mean1, 2))) * math.sum(arr2.map(y => Math.pow(y - mean2, 2))));

        return numerator / denominator;
    };

    const calculateFirstDifferences = (arr) => {
        const differences = [];
        for (let i = 1; i < arr.length; i++) {
            differences.push(arr[i].value - arr[i - 1].value);
        }
        return differences;
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const correlations20 = [];
            const correlations30 = [];
            const correlations40 = [];

            const callVolumeGamma = data.map(row => ({ time: row.time, value: row.total_call_volume_gamma }));
            const putVolumeGamma = data.map(row => ({ time: row.time, value: row.total_put_volume_gamma }));

            // Initialize with 0 values
            for (let i = 0; i < 15; i++) {
                correlations20.push({ time: callVolumeGamma[i]?.time || 0, value: 0 });
                if (i < 30) correlations30.push({ time: callVolumeGamma[i]?.time || 0, value: 0 });
                if (i < 40) correlations40.push({ time: callVolumeGamma[i]?.time || 0, value: 0 });
            }

            for (let i = 15; i < callVolumeGamma.length; i++) {
                const callVolumeGammaSlice20 = callVolumeGamma.slice(i - 15, i);
                const putVolumeGammaSlice20 = putVolumeGamma.slice(i - 15, i);

                const callVolumeDiffs20 = calculateFirstDifferences(callVolumeGammaSlice20);
                const putVolumeDiffs20 = calculateFirstDifferences(putVolumeGammaSlice20);


                const correlation20 = calculateCorrelation(callVolumeDiffs20, putVolumeDiffs20);

                correlations20.push({ time: callVolumeGamma[i].time, value: correlation20 });

                if (i >= 30) {
                    const callVolumeGammaSlice30 = callVolumeGamma.slice(i - 30, i);
                    const putVolumeGammaSlice30 = putVolumeGamma.slice(i - 30, i);

                    const callVolumeDiffs30 = calculateFirstDifferences(callVolumeGammaSlice30);
                    const putVolumeDiffs30 = calculateFirstDifferences(putVolumeGammaSlice30);

                    const correlation30 = calculateCorrelation(callVolumeDiffs30, putVolumeDiffs30);

                    correlations30.push({ time: callVolumeGamma[i].time, value: correlation30 });
                }

                if (i >= 40) {
                    const callVolumeGammaSlice40 = callVolumeGamma.slice(i - 40, i);
                    const putVolumeGammaSlice40 = putVolumeGamma.slice(i - 40, i);

                    const callVolumeDiffs40 = calculateFirstDifferences(callVolumeGammaSlice40);
                    const putVolumeDiffs40 = calculateFirstDifferences(putVolumeGammaSlice40);

                    const correlation40 = calculateCorrelation(callVolumeDiffs40, putVolumeDiffs40);

                    correlations40.push({ time: callVolumeGamma[i].time, value: correlation40 });
                }
            }

            // Update the zustand store with the new correlation values
            setCorrelations({
                correlations20,
                correlations30,
                correlations40,
            });

            // console.log('20-period correlations:', correlations20);
            // console.log('30-period correlations:', correlations30);
            // console.log('40-period correlations:', correlations40);
        } else {
            console.log('No data available for calculations');
        }
    }, [data, setCorrelations]);

    return null;
};

export default CorrelationComponent;